import React, { Component } from 'react';
import styled from 'styled-components';

import Logo from '../../component/Logo';

class Landing extends Component {
  componentDidMount = () => {
    setTimeout(() => {
      this.props.setProperty('loaded', true);
    }, 150);
    setTimeout(() => {
      this.props.setProperty('showLanding', false);
    }, 500);
  }

  render = () => (
    <Styled loaded={this.props.loaded}>
      <div className="container">
        <Logo id="landing-logo" />
        <div className="loading hidden">
          Loading please standby!
        </div>
      </div>
    </Styled>
  );
}

const Styled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: radial-gradient(#00142a, #000);
  position: absolute;
  opacity: 1;
  top: 0;
  left: 0;
  transition: all 0.25s ease;
  z-index: 1000;
  ${({ loaded }) => (loaded ? 'opacity: 0;' : '')}

  & .container {
    position: relative;
    display: flex;
    justify-content: center;

    & > div {
      max-height: 70px;
      height: auto;

      & > svg {
        max-width: 500px;
        width: 80vw;
      }
    }

    & .loading {
      color: #fff;
      padding: 12px;
      transition: all 0.25s ease;
      position: absolute;
      opacity: 0;
      bottom: -20px;
    }
  }
`;

export default Landing;
