import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { Layout, Menu, Badge, Tooltip } from 'antd';

import { sortBy } from '../../misc/utils';
import { page } from '../../../package.json';
import Routes from '../../routes';
import { Consumer } from '../Context/Context';
import Logo from '../../component/Logo';

library.add(fas);
library.add(fab);

const { Sider } = Layout;
const { Item, SubMenu } = Menu;

const countAllPosts = () => (
  Routes.find(route => route.path === '/blog').submenu
    .filter(route => route.path !== '/blog')
    .map(({count}) => count)
    .reduce((total, posts) => total + posts, 0)
);

const processRoute = route => {
  if (route.submenu && route.submenu.length > 0) {
    return (
      <SubMenu
        key={`submenu-${route.path}`}
        title={
          <div>
            {route.icon && <FAIcon icon={route.icon} />}
            <span>{route.name}</span>
          </div>
        }
      >
        {route.submenu
          .filter(subroute => subroute.name)
          .sort(sortBy('name'))
          .map(processRoute)}
      </SubMenu>
    );
  }

  const renderTooltipItem = () => {
    if (route.name.length >= 14) {
      return <Tooltip placement="right" title={route.name}>{renderItem()}</Tooltip>;
    }

    return renderItem();
  }

  const renderItem = () => (
    <Link to={route.path} id={`nav-${route.path.replace(/\//g, '-')}`}>
      {(route.icon && <FAIcon icon={route.icon} />) || (<span className="spacer" />)}
      <span>{route.name}</span>
      {route.path.startsWith('/blog') && (
        <Badge
          count={route.path === '/blog' ? countAllPosts() : route.count}
          style={{
            backgroundColor: '#000',
            color: '#999',
            boxShadow: '0 0 0 1px #444 inset',
          }}
        />
      )}
    </Link>
  );

  return (
    <Item key={`menu-${route.path}`}>
      {renderTooltipItem()}
    </Item>
  );
};

const PortfolioSider = ({ history, children }) => (
  <Consumer>
    {({
      broken,
      collapsedWidth,
      onBreakpoint,
      onCollapse,
      onClickMenuItem,
      onOpenChange,
      openKeys,
      selectedKey,
      sliderCollapsed,
      currentRoute,
    }) => (
      <Styled broken={broken}>
        <Sider
          breakpoint="lg"
          collapsed={sliderCollapsed}
          onCollapse={onCollapse}
          collapsedWidth={collapsedWidth}
          onBreakpoint={onBreakpoint}
        >
          <div className="logo">
            <h1>{page.title}</h1>
            <Logo />
          </div>
          <Menu
            selectedKeys={[`menu-${selectedKey}`]}
            forceSubMenuRender
            theme="dark"
            mode="inline"
            openKeys={openKeys}
            inlineIndent={4}
            onClick={onClickMenuItem}
            onOpenChange={onOpenChange}
          >
            {Routes.filter(route => route.name).map(processRoute)}
          </Menu>
        </Sider>
      </Styled>
    )}
  </Consumer>
);

const Styled = styled.div`
  position: fixed;
  z-index: 100;
  height: 100%;

  & .ant-layout-sider {
    background: #00142a;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0;
    z-index: 200;

    &-below {
      box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5);
    }

    & .ant-menu-root {
      padding-bottom: 40px;
    }

    & .logo {
      height: 65px;
      align-items: center;
      flex: 0;
      max-width: 200px;
      margin: 0;
      display: flex;
      flex: 1;
      justify-content: center;
      width: 100%;
      overflow: hidden;

      & h1 {
        display: none;
      }
    }

    & .ant-menu {
      overflow: auto;
      height: calc(100% - 60px);

      & .ant-menu-submenu-open,
      & .ant-menu-submenu-selected {
        color: rgba(255, 255, 255, 0.65);
      }

      & .ant-menu-submenu-title:hover {
        color: #fff;
      }

      & .ant-menu-item,
      & .ant-menu-submenu-title {
        padding-left: 10px !important;
      }

      & .ant-menu-item,
      & .ant-menu-item-group-title,
      & .ant-menu-submenu,
      & .ant-menu-item a,
      & .ant-menu-submenu a {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0;

        & svg {
          margin-right: 14px;
        }

        & > div {
          padding: 4px;
        }

        & span.spacer {
          display: inline-block;
          width: 1.25em;
          margin-right: 14px;
        }

        & .ant-badge {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }
  }
`;

export default PortfolioSider;
