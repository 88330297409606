import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, Breadcrumb } from 'antd';

import { getBreadcrumbDepth, getCurrentRoute } from '../../misc/utils';
import { Consumer } from '../Context/Context';

const { Header } = Layout;

const breadcrumbItem = (route, currentRoute) => {
  if (!route || !route.name) {
    return <h2>Unknown</h2>;
  }

  if (route.path === currentRoute && !route.submenu) {
    return <h2>{route.name}</h2>;
  } else {
    return <Link to={route.path}>{route.name}</Link>;
    // return <h2>{route.name}</h2>;
  }
}

const buildBreadcrumb = currentRoute => {
  return getBreadcrumbDepth(currentRoute).map(breadcrumb => {
    const route = getCurrentRoute(`/${breadcrumb}`);
    return (
      <Breadcrumb.Item key={`breadcrumb-${route.path}`}>
        {breadcrumbItem(route, currentRoute)}
      </Breadcrumb.Item>
    );
  });
};

const PortfolioLayout = ({ history, children }) => (
  <Consumer>
    {({ broken, currentRoute }) => (
      <Styled broken={broken}>
        <Header>
          <Breadcrumb>{buildBreadcrumb(currentRoute)}</Breadcrumb>
        </Header>
      </Styled>
    )}
  </Consumer>
);

const Styled = styled.div`
  & .ant-layout-header {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    background: #fff;
    padding: 0;

    & .ant-breadcrumb {
      margin: 16px 0;
      min-width: 0;
      flex-basis: 100%;
      padding: 0 10px;

      & a {
        color: rgba(180, 0, 0, 1);
      }

      & .ant-breadcrumb-separator {
        ${props => (props.broken ? 'margin: 0 4px;' : '')}
      }

      &::before {
        content: '//';
        margin: 0 5px;
      }

      & > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
      }

      & h2 {
        font-size: 14px;
        font-weight: normal;
        margin: 0;
        color: inherit;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    & > * {
      display: flex;
      flex: 0;
      align-items: center;
      padding: 0 10px;
    }
  }
`;

export default PortfolioLayout;
