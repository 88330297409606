import { css } from 'styled-components';

import Routes from '../routes';

export const flattenNestedArray = (nested, property) => (
  nested.reduce((flat, toFlatten) => {
    return toFlatten[property] &&
      toFlatten[property].length > 0 &&
      Array.isArray(toFlatten[property])
      ? [
          ...flat,
          toFlatten,
          ...flattenNestedArray(toFlatten[property], property),
        ]
      : [...flat, toFlatten];
  }, [])
);

export const getSubmenusToOpen = (currentRoute, array) => (
  array.reduce((flat, toFlatten) => {
    return toFlatten.submenu &&
      toFlatten.submenu.length > 0 &&
      Array.isArray(toFlatten.submenu)
      ? [
          ...flat,
          `submenu-${toFlatten.path}`,
          ...getSubmenusToOpen(currentRoute, toFlatten.submenu),
        ]
      : [...flat];
  }, ['submenu-/blog'])
);

export const getCurrentRoute = (currentRoute, selectedKey) => {
  const flatten = flattenNestedArray(Routes, 'submenu');
  const foundRoute = flatten.find((route) => route.path && route.path.includes(currentRoute));
  const foundPage = flatten.find((route) => route.path && route.path === selectedKey);

  const notFound = foundPage && selectedKey
    ? { plainPage: false }
    : { plainPage: true };

  return foundRoute ? foundRoute : notFound;
};

export const getBreadcrumbDepth = (currentRoute) => (
  currentRoute.split('/').filter(path => path)
);

export const conditionallyHide = (props, property, condition) => {
  if (props[property] === condition) {
    return css`
      display: none;
    `;
  }
};

export const sortBy = (property = null) => (a, b) => {
  const leftCompare = property ? a[property] : a;
  const rightCompare = property ? b[property] : b;

  if (leftCompare > rightCompare) {
    return 1;
  } else if (leftCompare < rightCompare) {
    return -1;
  }

  return 0;
};

export const scrollTo = (id, behavior = 'smooth') => {
  const element = document.querySelector(id);
  if (element) {
    element.scrollIntoView({
      block: 'start',
      inline: 'nearest',
      behavior,
    });
  }
};
