import React from 'react';
import PropTypes from 'prop-types';

const A = ({ href, name, children }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" aria-label={name}>
    {children}
  </a>
);

A.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default A;
