import React, { Component } from 'react';
import styled from 'styled-components';

class Overlay extends Component {
  componentDidMount = () => {
    document.querySelector('#overlay').addEventListener('touchmove', this.preventScroll, { passive: false });
    window.addEventListener('scroll', this.preventScroll, { passive: false });
  };

  componentWillUnmount = () => {
    document.querySelector('#overlay').removeEventListener('touchmove', this.preventScroll, { passive: false });
    window.removeEventListener('scroll', this.preventScroll, { passive: false });
  };

  preventScroll = (e) => {
    const { broken, sliderCollapsed } = this.props;

    if (broken && !sliderCollapsed) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }

  render = () => {
    const { broken, sliderCollapsed, onCollapse } = this.props;

    return (
      <Styled
        id="overlay"
        broken={broken}
        sliderCollapsed={sliderCollapsed}
        onClick={() => !sliderCollapsed && onCollapse()}
      />
    );
  };
};

const Styled = styled.div`
  display: block;
  position: fixed;
  background: #000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.25s ease;
  z-index: -1;

  ${({ broken, sliderCollapsed }) =>
    broken && !sliderCollapsed ? 'opacity: 0.75; z-index: 100;' : ''}
`;

export default Overlay;
