import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';

import { Consumer } from '../../container/Context/Context';

import LoadingPage from '../LoadingPage';

const Me = lazy(() => import('../../routes/Me'));
const BlogTopic = lazy(() => import('../../routes/BlogTopic'));
const BlogPage = lazy(() => import('../../routes/BlogPage'));
const Error404 = lazy(() => import('../../routes/Error404'));

const renderRoute = (Component, props, context) => (
  <Component {...props} />
);

const Router = () => (
  <Consumer>
    {context => (
      <Suspense fallback={<LoadingPage rows={10} />}>
        <Switch>
          <Route exact path="/" render={() => <Me />} />
          <Route exact path="/blog/:topic/:post" render={props => renderRoute(BlogPage, props, context)} />
          <Route exact path="/blog/:topic" render={props => renderRoute(BlogTopic, props, context)} />
          <Route exact path="/blog" render={props => renderRoute(BlogTopic, props, context)} />
          <Route render={() => <Error404 />} />
        </Switch>
      </Suspense>
    )}
  </Consumer>
);

export default Router;
