import React from 'react';
import styled from 'styled-components';
import { Layout, BackTop } from 'antd';

import { Consumer } from '../Context/Context';

import { getCurrentRoute } from '../../misc/utils';
import LayoutSider from './LayoutSider';
import LayoutHeader from './LayoutHeader';
import LayoutFooter from './LayoutFooter';
import Overlay from './Overlay';

const { Content } = Layout;

const PortfolioLayout = ({ children }) => (
  <Consumer>
    {({ loaded, broken, currentRoute, selectedKey, sliderCollapsed, onCollapse }) => {
      const thisPage = getCurrentRoute(currentRoute, selectedKey);
      const { plainPage } = thisPage;

      return (
        <Styled
          broken={broken}
          plainPage={plainPage}
          sliderCollapsed={sliderCollapsed}
        >
          <Layout>
            <Overlay
              broken={broken}
              sliderCollapsed={sliderCollapsed}
              onCollapse={onCollapse}
            />
            <LayoutSider onCollapse={onCollapse} />
            <Layout className="layout-margin">
              {!plainPage && <LayoutHeader />}
              <Content>{children}</Content>
              <LayoutFooter />
              <BackTop />
            </Layout>
          </Layout>
        </Styled>
      );
    }}
  </Consumer>
);

const Styled = styled.div`
  width: 100%;
  height: 100%;

  & .ant-layout {
    height: 100%;
    width: ${({ broken }) => (broken ? '100%' : 'calc(100% - 100px)')};
    flex: 1 0 auto;
    ${props => (props.broken ? 'flex: 1 0 100%;' : '')}

    & .layout-margin {
      transition: filter 0.25s ease;
      filter: none;

      ${({ broken }) => (broken ? 'margin-left: 0;' : 'margin-left: 200px;')}
      ${'' /* ${({ broken, sliderCollapsed }) => (broken && !sliderCollapsed ? 'filter: grayscale(100%);' : '')} */}
    }

    & .ant-back-top {
      right: 50px;
    }

    .ant-layout-sider-zero-width-trigger {
      z-index: 10;
    }

    & .ant-layout-content {
      margin: 0 16px;
      padding: 24px;
      min-height: 280px;
      flex: 1 0 auto;
      word-break: break-word;
      ${({ plainPage }) => !plainPage && 'background: #fff;'}

      & a {
        color: rgba(180, 0, 0, 1);

        &:hover {
          color: #f22a27;
        }
      }
    }
  }
`;

export default PortfolioLayout;
