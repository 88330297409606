import React from 'react';
import ReactDOM, { render as ReactDOMRender } from 'react-dom';
import { Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import AppProvider from './container/Context/AppProvider';
import App from './container/App';

if (process.env.NODE_ENV !== 'production') {
  const axe = require('react-axe');
  axe(React, ReactDOM, 2500);
}

const mount = document.getElementById('root');

const render = Component => {
  ReactDOMRender(
    <BrowserRouter>
      <Switch>
        <AppProvider>
          <Component />
        </AppProvider>
      </Switch>
    </BrowserRouter>, mount);
};

if (module.hot) {
  module.hot.accept();
  const NextApp = require('./container/App').default;
  render(NextApp);
}

const loading = document.querySelector('.loading');

if (loading) {
  loading.classList.add('loaded');
}

render(App);
