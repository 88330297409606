import React, { Component } from 'react';

import INITIAL_STATE from './InitialState';
import { Provider } from './Context';

import { scrollTo } from '../../misc/utils';

class AppProvider extends Component {
  state = {
    ...INITIAL_STATE,
    setProperty: (property, value) => {
      this.setState(prevState => ({
        ...prevState,
        [property]: value,
      }));
    },
    onBreakpoint: broken => {
      this.setState(prevState => ({
        ...prevState,
        broken,
        collapsedWidth: broken ? 0 : 80,
      }));
    },
    onCollapse: () => {
      const { sliderCollapsed, selectedKey } = this.state;

      this.setState(prevState => ({
        ...prevState,
        sliderCollapsed: !prevState.sliderCollapsed,
      }));

      if (sliderCollapsed) {
        setTimeout(() => {
          scrollTo(`#nav-${selectedKey.replace(/\//g, '-')}`, 'auto');
        }, 100);
      }
    },
    onOpenChange: (openKeys) => {
      const latestOpenKey = openKeys.find(key => !this.state.openKeys.includes(key));
      this.setState(prevState => ({
        ...prevState,
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      }));
    },
    onClickMenuItem: ({ item, key, keypath }) => {
      this.setState(prevState => ({
        ...prevState,
        sliderCollapsed: prevState.broken && !prevState.sliderCollapsed,
        selectedItem: key,
      }));
    },
    handleEnterRoute: (props) => {
      if (
        props.location.pathname === this.state.currentRoute
        && props.match.params.topic !== this.state.topic
      ) {
        this.setState(prevState => ({
          ...prevState,
          topic: props.match.params.topic,
        }));
      }
    },
  };

  static getDerivedStateFromProps = (props, state) => {
    const currentRoute = props.location.pathname;
    const regex = new RegExp('^(?<page>/(.*?)?)(?<topic>/.*?)?(?<post>/.*)?$');
    const { groups } = regex.exec(currentRoute);
    const { page, topic, post } = groups;

    let sliderCollapsed = state.sliderCollapsed === null ? false : state.sliderCollapsed;
    if (state.sliderCollapsed === null && window.innerWidth <= 992) {
      sliderCollapsed = true;
    }

    return {
      ...state,
      currentRoute,
      sliderCollapsed,
      selectedKey: `${page}${topic || ''}`,
      routeRegex: {
        page,
        topic,
        post,
      },
    };
  }

  render = () => <Provider value={this.state}>{this.props.children}</Provider>;
}

export default AppProvider;
