import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { Consumer } from '../Context';
import Layout from '../Layout';

import Router from '../../component/Router';
import Landing from '../../component/Landing';

const App = () => (
  <Consumer>
    {({ broken, sliderCollapsed, loaded, showLanding, setProperty }) => (
      <React.Fragment>
        <GlobalStyles
          broken={broken}
          sliderCollapsed={sliderCollapsed}
        />
        {showLanding && <Landing loaded={loaded} setProperty={setProperty} />}
        {loaded && (
          <Layout>
            <Router />
          </Layout>
        )}
      </React.Fragment>
    )}
  </Consumer>
);

const GlobalStyles = createGlobalStyle`
  html, body {
    width: 100%;
    height: auto !important;
    min-height: 100% !important;
    margin: 0;
    padding: 0;
    display: flex;
    ${({ broken, sliderCollapsed }) => broken && !sliderCollapsed ? 'overflow: hidden;' : 'overflow-x: hidden;'}
  }

  #root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-tooltip-placement-right {
    left: 200px !important;
  }
`;

export default App;
