const Routes = [
  {
    path: '/',
    icon: ['fas', 'user-circle'],
    name: 'Who am I',
    plainPage: true,
  },
  {
    path: '/projects',
    name: 'Projects',
    icon: ['fas', 'project-diagram'],
    plainPage: true,
    submenu: [
      {
        path: '/project',
        name: 'All',
        plainPage: true,
      },
      {
        path: '/project/dotfiles',
        name: 'Dot files',
        plainPage: true,
      },
      {
        path: '/project/cozify-rest-api-client',
        name: 'Cozify REST API Node.js client',
        plainPage: true,
      },
      {
        path: '/project/portfolio',
        name: 'Portfolio',
        plainPage: true,
      },
      {
        path: '/project/posti',
        name: 'Posti',
        plainPage: true,
      },
      {
        path: '/project/posti-graphql',
        name: 'Posti-GraphQL',
        plainPage: true,
      },
      {
        path: '/project/sloth',
        name: 'Sloth',
        plainPage: true,
      },
      {
        path: '/project/restrict-iptables-by-country',
        name: 'Restrict iptables by country',
        title: 'Restrict iptables by country',
        plainPage: true,
      },
    ],
  },
  {
    path: '/blog',
    name: 'Blog',
    icon: ['fas', 'blog'],
    plainPage: true,
    submenu: [
      {
        path: '/blog',
        name: 'All',
        plainPage: true,
      },
      {
        path: '/blog/bash',
        name: 'Bash',
        plainPage: true,
        count: 2,
      },
      {
        path: '/blog/docker',
        name: 'Docker',
        plainPage: true,
        count: 3,
      },
      {
        path: '/blog/electron',
        name: 'Electron',
        plainPage: true,
        count: 1,
      },
      {
        path: '/blog/expressjs',
        name: 'Express.js',
        plainPage: true,
        count: 4,
      },
      {
        path: '/blog/gitlab',
        name: 'GitLab',
        plainPage: true,
        count: 3,
      },
      {
        path: '/blog/graphql',
        name: 'GraphQL',
        plainPage: true,
        count: 2,
      },
      {
        path: '/blog/linux',
        name: 'Linux',
        plainPage: true,
        count: 1,
      },
      {
        path: '/blog/nodejs',
        name: 'Node.js',
        plainPage: true,
        count: 4,
      },
      {
        path: '/blog/nginx',
        name: 'Nginx',
        plainPage: true,
        count: 5,
      },
      {
        path: '/blog/reactjs',
        name: 'React.js',
        plainPage: true,
        count: 9,
      },
      {
        path: '/blog/redux',
        name: 'Redux',
        plainPage: true,
        count: 2,
      },
      {
        path: '/blog/socketio',
        name: 'Socket.IO',
        plainPage: true,
        count: 2,
      },
    ],
  },
];

export default Routes;
