import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Skeleton } from 'antd';

const LoadingPage = ({ rows }) => (
  <Styled>
    <Skeleton loading active paragraph={{ rows }} />
  </Styled>
)

const Styled = styled.div`
  & .ant-skeleton-title {
    margin-top: 0;
  }
`;

LoadingPage.defaultProps = {
  rows: 4,
};

LoadingPage.propTypes = {
  rows: PropTypes.number,
};

export default LoadingPage;
