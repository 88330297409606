const INITIAL_STATE = {
  loaded: true,
  sliderCollapsed: null,
  collapsedWidth: 0,
  broken: true,
  currentRoute: null,
  selectedItem: null,
  showLanding: false,
  openKeys: ['submenu-/blog'],
  routeRegex: {
    page: null,
    topic: null,
    post: null,
  },
};

export default INITIAL_STATE;
